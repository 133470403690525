import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "src/app/config/AppSettings";
import { GridPage } from "../util/components/gridtable/gridtable.component";

@Injectable()
export class OperatorService {
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  saveOperator(userrole) {
    let header = new HttpHeaders();
    return this.http
      .post(this.appsetting.get("server-url") + "/api/operator", userrole, {
        headers: header
      })
      .map(res => res);
  }

  getOperatorDetail(operatorId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("operatorId", operatorId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/operator", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getOperatorList(pageinfo: GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/operatorlist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }

  getAllOperators() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/alloperators", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  getAllOperatorsWithSuccessor() {
    let header = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/alloperatorswithsuccessors",
        {
          headers: header,
          observe: "body",
          responseType: "json"
        }
      )
      .map(res => res);
  }

  deleteOperator(operatorId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("operatorId", operatorId.toString());
    return this.http
      .delete(this.appsetting.get("server-url") + "/api/deleteoperator", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
}
