import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../config/AppSettings';
import {GridPage} from '../util/components/gridtable/gridtable.component';

@Injectable({
  providedIn: 'root'
})
export class UserroleService {

  constructor(private http: HttpClient, private appsetting : AppSettings) { } 
    getRoleDetail(roleId : number) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("roleId", roleId.toString());     
      return this.http.get( this.appsetting.get("server-url") + '/api/role',
        {headers : header, observe : "body", params : param,responseType : "json"})
      .map(res=>res);
    }
    getRoleList(pageinfo : GridPage, searchField) {
      let header = new HttpHeaders();
      let httpParam : HttpParams=new HttpParams();
      httpParam=httpParam.append("page", pageinfo.number.toString());
      httpParam=httpParam.append("size",pageinfo.size.toString());
      httpParam=httpParam.append("searchText", searchField.searchText);      
      return this.http.get( this.appsetting.get("server-url") + '/api/roles',{headers : header, observe : "body", params : httpParam,responseType : "json"})
      .map(res=>res);
    }
    saveRole(userrole) {
      let header = new HttpHeaders();      
      return this.http.post( this.appsetting.get("server-url") + '/api/role',userrole,{headers : header})
      .map(res => res);
    }

    getAllPriv() {
      return this.http.get( this.appsetting.get("server-url") + '/api/allprivileges',{observe : "body", responseType : "json"})
      .map(res=>res);
    }

    getRoleIdName() {    
      return this.http.get( this.appsetting.get("server-url") + '/api/roleidname',{observe : "body",responseType : "json"})
      .map(res=>res);
    }
}
