import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { AppSettings } from '../config/AppSettings';
import {GridPage} from '../util/components/gridtable/gridtable.component';
import { UserModel } from '../models/user.model';
@Injectable()
export class UserService {

  constructor(private http: HttpClient, private appsetting : AppSettings) { } 
    getUserDetail(userId : number) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("userId", userId.toString());     
      return this.http.get( this.appsetting.get("server-url") + '/api/user',
        {headers : header, observe : "body", params : param,responseType : "json"})
      .map(res=>res);
    }
    getUserList(pageinfo : GridPage, searchField) {
      let header = new HttpHeaders();
      let httpParam : HttpParams=new HttpParams();
      httpParam=httpParam.append("page", pageinfo.number.toString());
      httpParam=httpParam.append("size",pageinfo.size.toString());
      httpParam=httpParam.append("searchText", searchField.searchText);
      httpParam=httpParam.append("searchDistributorId", searchField.distributorId);
      httpParam=httpParam.append("searchRoleId", searchField.roleId);
      //header.append('Content-Type','application/json');

      return this.http.get( this.appsetting.get("server-url") + '/api/users',{headers : header, observe : "body", params : httpParam,responseType : "json"})
      .map(res=>res);
    }
    saveUser(user : object) {
      let header = new HttpHeaders();
      //header.append('Content-Type','application/json');
      return this.http.post( this.appsetting.get("server-url") + '/api/user',user,{headers : header})
      .map(res => res);
    }
    deleteUser(userId : number) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("userId", userId.toString());         
      return this.http.delete( this.appsetting.get("server-url") + '/api/user',
        {headers : header,observe : "body", params : param,responseType : "json"})
      .map(res=>res);
    }
    inactiveUser(userId : number) {
      let header = new HttpHeaders();
      let param : HttpParams=new HttpParams();
      param=param.append("userId", userId.toString());     
      let objUser : UserModel = new UserModel();
      objUser.userId = userId;
      return this.http.post( this.appsetting.get("server-url") + '/api/inactiveuser',objUser,
        {headers : header,observe : "body", responseType : "json"})
      .map(res=>res);
    }
}
