import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../config/AppSettings";

@Injectable()
export class ChargerActionQueueService {
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  saveOperator(userrole) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/chargerrequestactionqueue",
        userrole,
        {
          headers: header
        }
      )
      .map(res => res);
  }
}
