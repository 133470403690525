import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from "../config/AppSettings";

@Injectable()
export class OCPPStatusMasterService {
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  getRemoteStatusMasterList() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/ocppremotestatus", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }
}
