import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "src/app/config/AppSettings";
import { GridPage } from "../util/components/gridtable/gridtable.component";
import { ProfileOcppModel } from "../models/ProfileOcppModel.model";

@Injectable()
export class ProfileOcppService {
    constructor(private http: HttpClient, private appsetting: AppSettings) {}
    getProfileOcppList(pageinfo: GridPage, searchField) {
        let header = new HttpHeaders();
        let httpParam: HttpParams = new HttpParams();
        httpParam = httpParam.append("page", pageinfo.number.toString());
        httpParam = httpParam.append("size", pageinfo.size.toString());
        httpParam = httpParam.append("searchText", searchField.searchText);
        return this.http
          .get(this.appsetting.get("server-url") + "/api/chargerOcppProfilelist", {
            headers: header,
            observe: "body",
            params: httpParam,
            responseType: "json"
          })
          .map(res => res);
      }
      getProfileOcppDetail(chargepointrserialnumber: string) {
        let header = new HttpHeaders();
        let param: HttpParams = new HttpParams();
        param = param.append("chargepointrserialnumber", chargepointrserialnumber);
        return this.http
          .get(this.appsetting.get("server-url") + "/api/chargerOcppProfileData", {
            headers: header,
            observe: "body",
            params: param,
            responseType: "json"
          })
          .map(res => res);
      }
}