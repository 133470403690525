import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import {ImageconfigService} from '../../services/imageconfig.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {

  npassword : {type: String};
  cpassword : {type : String};
  key : String ="";
  hideinvlidloginmsg : boolean =true;
  loginerrormsg: string ="";
  constructor(private authService : AuthService,private router : Router, public imagePath : ImageconfigService,
    private route: ActivatedRoute) {
    this.hideinvlidloginmsg =true;
    this.loginerrormsg="";
   }

  ngOnInit() {
    //this.key = this.route.snapshot.paramMap.get("key")
    const routeParams = this.route.snapshot.queryParams;
    this.key = routeParams.key;
  }

  onChangeSubmit() {
    if(this.npassword!= this.cpassword){
      this.hideinvlidloginmsg =false;
      this.loginerrormsg= "New And Confirm Password Not Same";
      return false;
    }
    else {
      const resetdata = {
        password: this.npassword,
        key: this.key
      }
      this.authService.resetpassowrd(resetdata).subscribe(data => {
        
        
      },
      );
      this.hideinvlidloginmsg =true;
      this.loginerrormsg= "";
    }   
    
  }

}
