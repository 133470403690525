export class SortClass {
  sortDirection: string;
  sortFieldName: string;
}

export class SimpleMessage {
  title: string;
  message: string;
  btnClass: string;
  fnResult: Function;
  fnReason: Function;
  _scope: any;
}
