import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from 'src/app/config/AppSettings';
import { GridPage } from '../util/components/gridtable/gridtable.component';
import { ChargerModel } from '../models/Charger.model';
import { IfStmt } from '@angular/compiler';
import { filter } from 'rxjs/operators';
import { NumberSymbol } from '@angular/common';

@Injectable()
export class ChargerService {
    public chargerTypeList : Array<ChargerModel>;
    constructor(private http: HttpClient, private appsetting : AppSettings) {
        this.getAllChargerType();
       
    }

    
    async getChargerData(chargerType:string) : Promise<ChargerModel> {
        if(!chargerType) {
            return undefined;
        }
        if(chargerType.length !=6) {
            return undefined;
        }
        return await this.getCharger(chargerType).then(data => {
            if(data["success"]) {
                const chargerdata : ChargerModel=data["result"];
                return chargerdata;
            } else {
                console.error("chargertype not found");
                return undefined;
            }
        })
    }

      async getCharger(chargerType: string) {
        let header = new HttpHeaders();
        let param : HttpParams=new HttpParams();
        param=param.append("chargerType", chargerType.toString());     
        const response= await this.http.get( this.appsetting.get("server-url") + '/api/charger',
          {headers : header, observe : "body", params : param,responseType : "json"})
        .map(res=>res).toPromise();
        return response;
      }
      getChargerList(pageinfo : GridPage, searchField) {
        let header = new HttpHeaders();
        let httpParam : HttpParams=new HttpParams();
        httpParam=httpParam.append("page", pageinfo.number.toString());
        httpParam=httpParam.append("size",pageinfo.size.toString());
        httpParam=httpParam.append("searchText", searchField.searchText);      
        return this.http.get( this.appsetting.get("server-url") + '/api/chargerlist',{headers : header, observe : "body", params : httpParam,responseType : "json"})
        .map(res=>res);
      }
      

      getAllChargerType() {
        let header = new HttpHeaders();
        this.http.get( this.appsetting.get("server-url") + '/api/allchargers',{headers : header, observe : "body",responseType : "json"})
        .map(res=>res).subscribe(data => {
            if(data["success"]) {
                this.chargerTypeList=data["result"];
            } else {
                this.chargerTypeList=undefined;
            }
        });
      }

      getChargerDataByType(chargerType : string) {
          if(this.chargerTypeList && this.chargerTypeList.length >0) {
            const chargerItemArr = this.chargerTypeList.filter(itm => itm.chargerType==chargerType);
            if(chargerItemArr && chargerItemArr.length>0) {
                return chargerItemArr[0];
            } else {
                return undefined;
            }
          } else {
              return undefined;
          }
      }

     
}