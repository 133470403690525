import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppSettings } from 'src/app/config/AppSettings';
import { GridPage } from '../util/components/gridtable/gridtable.component';
import { CityModel } from '../models/City.model';

@Injectable()
export class CityService {
    constructor(private http: HttpClient, private appsetting : AppSettings) {

    }

    
    async getPincodeData(pincode:string) : Promise<CityModel> {
        if(!pincode) {
            return undefined;
        }
        if(pincode.length !=6) {
            return undefined;
        }
        return await this.getCity(pincode).then(data => {
            if(data["success"]) {
                const citydata : CityModel=data["result"];
                return citydata;
            } else {
                console.error("pincode not found");
                return undefined;
            }
        })
    }

      async getCity(pincode: string) {
        let header = new HttpHeaders();
        let param : HttpParams=new HttpParams();
        param=param.append("pincode", pincode.toString());     
        const response= await this.http.get( this.appsetting.get("server-url") + '/api/app/city',
          {headers : header, observe : "body", params : param,responseType : "json"})
        .map(res=>res).toPromise();
        return response;
      }
      getCityList(pageinfo : GridPage, searchField) {
        let header = new HttpHeaders();
        let httpParam : HttpParams=new HttpParams();
        httpParam=httpParam.append("page", pageinfo.number.toString());
        httpParam=httpParam.append("size",pageinfo.size.toString());
        httpParam=httpParam.append("searchText", searchField.searchText);      
        return this.http.get( this.appsetting.get("server-url") + '/api/citylist',{headers : header, observe : "body", params : httpParam,responseType : "json"})
        .map(res=>res);
      }
      getAllCharger() {
        let header = new HttpHeaders();
        return this.http.get( this.appsetting.get("server-url") + '/api/allcharger',{headers : header, observe : "body",responseType : "json"})
        .map(res=>res);
      }

}