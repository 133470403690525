import { Injectable } from '@angular/core';
import {HttpClient,HttpResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
@Injectable()
export class ImageconfigService {

  private imagePathList: any
  constructor(private _http:HttpClient) { 
    this.loadImageFilesPath().toPromise().then(imagePaths =>{
      this.imagePathList = imagePaths;      
    });
  }

  loadImageFilesPath () {    
    
    return this._http.get("assets/config/imagepath.json")
      .map((response: Response) => response)
  }
  getImageFilePath(key : string) {
    if(this.imagePathList) {
      return this.imagePathList[key] ;
    } else {
      return "assets/images/" + key + ".png";
    }
  }
  getIconFilePath(key : string) {
    if(this.imagePathList) {
      return this.imagePathList[key] ;
    } else {
      return "assets/images/" + key + ".ico";
    }
  }
  }
